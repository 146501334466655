import Vue from "vue";
import VueRouter from "vue-router";

import JwtService from "@/common/jwt.service";
import store from "@/store";
import roleMixin from "@/mixins/role.js";
import { MessageBox } from "element-ui";
import i18n from "@/i18n";
import Theme from "@/common/theme";
import baseDomainPath from "@/common/baseDomainPath";

import apiPageView from "@/apis/page-view";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    props: true,
    component: () => import("@/views/LandingPage.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/views/Terms.vue")
  },
  {
    path: "/password/forgot",
    name: "ForgetPassword",
    component: () => import("@/views/ForgetPassword.vue")
  },
  {
    path: "/password/set",
    name: "SetPassword",
    component: () => import("@/views/SetPassword.vue")
  },
  {
    path: "/password/reset",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/register-detail",
    name: "RegisterDetail",
    component: () => import("@/views/RegisterDetail.vue"),
    meta: {
      requiresAuth: true,
      skipCheckBind: true
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/user/UserProfile.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/user/:id",
    name: "ProfileForAdmin",
    component: () => import("@/views/user/ProfileForAdminEditUser.vue"),
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  // ===== TOEFL系统 ======
  {
    path: "/toefl",
    name: "ToeflList",
    component: () => import("@/views/toefl/toeflList/Index.vue")
  },
  {
    path: "/browse",
    name: "Browse",
    component: () => import("@/views/toefl/toeflList/Browse.vue")
  },
  {
    path: "/systemcheck",
    name: "SystemCheck",
    component: () => import("@/views/SystemCheck.vue")
  },
  {
    path: "/scorereport",
    name: "ToeflScorereport",
    component: () => import("@/views/toefl/transcript/Scorereport.vue")
  },
  {
    path: "/setTest",
    name: "SetTest",
    component: () => import("@/views/toefl/toeflTest/Index.vue")
  },
  {
    path: "/setTest/ivyway",
    name: "SetTestForIvyway",
    component: () => import("@/views/toefl/toeflTest/IndexForIvyway.vue")
  },
  {
    path: "/startTest",
    name: "StartTest",
    component: () => import("@/views/toefl/toeflTest/Start.vue")
  },
  {
    path: "/m/setTest",
    name: "MSetTest",
    component: () => import("@/views/toefl/toeflTest/mobile/Index.vue")
  },
  {
    path: "/testresults",
    name: "ToeflTestResults",
    component: () => import("@/views/toefl/toeflTestResults/Index.vue")
  },
  {
    path: "/testresults/combine",
    name: "ToeflTestResultsCombine",
    component: () => import("@/views/toefl/toeflTestResults/CombineTests.vue")
  },
  {
    path: "/transcript",
    name: "Transcript",
    component: () => import("@/views/toefl/transcript/Index.vue")
  },
  
  {
    path: "/result",
    name: "toeflResult",
    component: () => import("@/views/toefl/transcript/Index.vue")
  },
  {
    path: "/result/pdf",
    name: "ResultPDF",
    component: () => import("@/views/toefl/transcript/PDF.vue")
  },
  {
    path: "/gradingsamples",
    name: "Gradingsamples",
    component: () => import("@/views/toefl/transcript/Example.vue")
  },
  {
    path: "/explanation",
    name: "ToeflResolve",
    component: () => import("@/views/toefl/toeflResolve/Index.vue"),
    meta: {
      showFooter: false
    }
  },
  {
    path: "/browse/detail",
    name: "ToeflResolveBrowse",
    component: () => import("@/views/toefl/toeflResolve/Browse.vue"),
    meta: {
      showFooter: false
    }
  },
  {
    path: "/test",
    name: "ToeflTest",
    component: () => import("@/views/toefl/toeflTest/Test.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/m/test",
    name: "MToeflTest",
    component: () => import("@/views/toefl/toeflTest/mobile/Test.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  //Grading
  {
    path: "/report",
    name: "ToeflGrading",
    component: () => import("@/views/toefl/grading/Index.vue")
  },
  {
    path: "/promotionCode",
    name: "PromotionCode",
    component: () => import("@/views/toefl/promotionCode/Index.vue")
  },
  //GradingList
  {
    path: "/grading",
    name: "ToeflTestGrading",
    component: () => import("@/views/toefl/gradingList/Index.vue")
  },
  //Balance
  {
    path: "/balance",
    name: "ToeflBalance",
    component: () => import("@/views/toefl/balance/Index.vue")
  },
  //Balance
  {
    path: "/profile/balance",
    name: "ToeflProfileBalance",
    component: () => import("@/views/toefl/balance/Index.vue")
  },
  // Email Verify
  {
    path: "/verify",
    name: "Verify",
    component: () => import("@/views/Verify.vue"),
    meta: {
      skipCheckBind: true
    }
  },
  // Email Verify
  {
    path: "/writingContent",
    name: "WritingContent",
    component: () => import("@/views/toefl/toeflTest/writing/ViewAll.vue"),
    meta: {
      skipCheckBind: true
    }
  },
  //Plans, plan 相關頁面開頭都要 ToeflPlans
  {
    path: "/plans",
    name: "ToeflPlans",
    component: () => import("@/views/toefl/plans/Index.vue")
  },
  {
    path: "/plans/setting",
    name: "ToeflPlansSetting",
    component: () => import("@/views/toefl/plans/Setting.vue")
  },
  {
    path: "/plans/grading",
    name: "ToeflPlansGrading",
    component: () => import("@/views/toefl/plans/Grading.vue")
  },
  {
    path: "/plans/tcoins",
    name: "ToeflPlansTCoins",
    component: () => import("@/views/toefl/plans/TCoins.vue")
  },
  //Analytics
  {
    path: "/analytics",
    name: "ToeflAnalytics",
    component: () => import("@/views/toefl/analytics/Index.vue"),
    meta: {
      isAdminPage: true
    }
  },
  {
    path: "/payments",
    name: "ToeflPayments",
    component: () => import("@/views/toefl/payments/Index.vue"),
    meta: {
      isAdminPage: true
    }
  },
  {
    path: "/page-view",
    name: "ToeflPageView",
    component: () => import("@/views/toefl/page-view/Index.vue"),
    meta: {
      isAdminPage: true
    }
  },
  {
    path: "/rev-ai-analytics",
    name: "ToeflRevAiStats",
    component: () => import("@/views/toefl/rev-ai-stats/Index.vue"),
    meta: {
      isAdminPage: true
    }
  },
  {
    path: "/students",
    name: "ToeflStudents",
    component: () => import("@/views/toefl/students/Index.vue"),
    meta: {
      isAdminPage: true
    }
  },
  {
    path: "/flashcards",
    name: "Flashcards",
    component: () => import("@/views/toefl/flashcards/Index.vue")
  },
  {
    path: "/myflashcards",
    name: "MyFlashcards",
    component: () => import("@/views/toefl/flashcards/Index.vue")
  },
  {
    path: "/flashcards/vocabularies/list",
    name: "FlashcardsVocabulariesList",
    props: true,
    component: () => import("@/views/toefl/flashcards/VocabulariesList.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    },
    beforeEnter (to, from, next) {
      store.commit("flashcard/initVocabList");
      next();
    }
  },
  {
    path: "/flashcards/vocabularies/card",
    name: "FlashcardsVocabulariesCard",
    props: true,
    component: () => import("@/views/toefl/flashcards/VocabulariesCards.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    },
    beforeEnter (to, from, next) {
      store.commit("flashcard/initVocabList");
      next();
    }
  },
  {
    path: "/flashcards/myvocabularies/list",
    name: "MyFlashcardsVocabulariesList",
    props: true,
    component: () => import("@/views/toefl/flashcards/VocabulariesList.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    },
    beforeEnter (to, from, next) {
      store.commit("flashcard/initVocabList");
      next();
    }
  },
  {
    path: "/flashcards/myvocabularies/card",
    name: "MyFlashcardsVocabulariesCard",
    props: true,
    component: () => import("@/views/toefl/flashcards/VocabulariesCards.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    },
    beforeEnter (to, from, next) {
      store.commit("flashcard/initVocabList");
      next();
    }
  },
  {
    path: "/lessons",
    name: "Lessons",
    component: () => import("@/views/toefl/lessons/Index.vue")
  },
  {
    path: "/lessons",
    name: "Lesson",
    component: () => import("@/views/toefl/lessons/Index.vue")
  },
  {
    path: "/lesson/create",
    name: "CreateLessons",
    component: () => import("@/views/toefl/lessons/Create.vue")
  },
  {
    path: "/lesson/edit",
    name: "EditLessons",
    component: () => import("@/views/toefl/lessons/Edit.vue")
  },
  {
    path: "/materials",
    name: "MyMaterials",
    component: () => import("@/views/toefl/materials/Index.vue")
  },
  {
    path: "/materials/all",
    name: "Materials",
    component: () => import("@/views/toefl/materials/Index.vue")
  },
  {
    path: "/AllContent",
    name: "AllContent",
    component: () => import("@/views/toefl/toeflTest/ViewAll.vue"),
    meta: {
      skipCheckBind: true
    }
  },
  // TODO: 404
  {
    path: "/404",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFound.vue")
  },
  {
    path: "*",
    name: "RedirectToNotFound",
    component: () => import("@/views/RedirectToNotFound.vue")
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "development" ? process.env.BASE_URL : "/toefl/",
  routes,
  // 導向新的 router 會到新頁面的 top
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
router.beforeEach(async (to, from, next) => {
  const ignoreRemeberPage = [
    "Login",
    "Register",
    "PageNotFound",
    "ForgetPassword",
    "SetPassword",
    "ResetPassword",
    "Verify"
  ];
  updateFavicon("name", Theme.icon, Theme.image);
  if (!ignoreRemeberPage.includes(to.name)) {
    localStorage.setItem("lastAccessRoutePath", to.fullPath);
  }
  if (to.meta.requiresAuth && !JwtService.hasToken()) {
    next({ name: "Login" });
  }

  if (!to.meta.skipCheckBind && JwtService.hasToken()) {
    await store.dispatch("user/checkFinishBind");
    if (!store.state.user.finishBind) {
      next({ name: "RegisterDetail" });
    }
    await store.dispatch("user/getProfile");

    // Check Role Is Admin
    if (to.meta.isAdminPage && !roleMixin.methods.isRoleAdmin()) {
      next({ name: "PageNotFound" });
    }
  }

  // clear previous order
  if (
    from.name !== "Login" &&
    ["ToeflProfileBalance", "ToeflBalance", "ToeflPlansTCoins"].includes(to.name)
  ) {
    window.localStorage.removeItem("PREVIOUS_ORDER");
  }
  next();
});

const updateFavicon = (title, imgPath, image) => {
  const link = document.querySelector("link[rel*='icon']");
  link.rel = "icon";
  link.href = baseDomainPath + imgPath + "?" + new Date().getTime();

  const meta = document.querySelector("meta[property*='image']");
  meta.property = "og:image";
  meta.content = image;
};
router.onError(error => {
  console.log("error", error);
  console.log("error.message", error.message);
  const pattern = "Loading chunk";
  const isChunkLoadingFailed = error.message.includes(pattern);
  const cssPattern = "Loading CSS chunk";
  const isCSSChunkLoadingFailed = error.message.includes(cssPattern);
  if (isChunkLoadingFailed || isCSSChunkLoadingFailed) {
    MessageBox({
      title: i18n.t("message.notice"),
      message: `
        ${i18n.t("message.chunkLoadingError1")}<a target="_blank" href="mailto:support@testadmit.com">
        ${i18n.t("message.chunkLoadingError2")}</a>${i18n.t("message.chunkLoadingError3")}
      `,
      confirmButtonText: i18n.t("alert.refresh"),
      showClose: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      closeOnHashChange: false,
      dangerouslyUseHTMLString: true,
      callback: () => window.location.reload()
    });
  }
});

router.afterEach(to => {
  setTimeout(() => {
    apiPageView.post({ url: window.location.origin + to.fullPath });
  }, 300);
});

export default router;
