import ApiService from "@/common/api.service";

export default {
  get(params) {
    return ApiService.query("page-views", params);
  },
  post(data) {
    return ApiService.post("page-views", data);
  }
};
